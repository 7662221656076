<template>

      <table cellpadding="0" cellspacing="0" border="0" style="margin-top: 16px">
        <colgroup>
          <col width="15%">
          <col width="14%">
          <col width="20%">
          <col width="19%">
          <col width="20%">
          <col width="10%">
        </colgroup>
        <thead>
        <tr>
          <th>회차</th>
          <th>시간</th>
          <th>베팅내역</th>
          <th>베팅금액</th>
          <th>적중금액</th>
          <th>상태
            <i class="fa fa-refresh" style="cursor: pointer" @click="initLatelyBet"></i>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in latelyBet" :key="index">
          <td>{{item.leisureGame.sequence}}</td>
          <td>{{item.createTime|datef('DD일 HH:mm')}}</td>
          <td class="mini_bet_section">
            <p style="color: #808080">{{item.attributeConfig.attrName}}</p>
            <p>{{item.selectedValueText}}</p>
          </td>
          <td>
            <p>{{item.betCash|comma}}</p>
          </td>
          <td>
            <p>{{item.totalCash|comma}}</p>
          </td>
          <td>
            <p style="color: #fc4646;" v-if="item.betResult === sportsConst.BET_RESULT_LOSE" class="text-red">낙첨</p>
            <p v-if="item.betResult === sportsConst.BET_RESULT_SPECIALCASE" class="text-orange">적특</p>
            <p v-if="item.betResult === sportsConst.BET_RESULT_CANCEL" class="text-red">취소</p>
            <p v-if="item.betResult === sportsConst.BET_RESULT_WIN" class="text-skyblue">당첨</p>
            <p v-if="item.betResult === sportsConst.BET_RESULT_WAITING">대기</p>
          </td>
        </tr>
        </tbody>

      </table>

</template>

<script>
  import {getlatelyBet} from "../../network/leisureRequest";
  import leisureConst from "../../common/leisureConst";
  import sportsConst from "../../common/sportsConst";
  import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";

  export default {
    name: "LeisureLatelyBetListComp",
    props: {
      kind: {
        type: Number,
        default() {
          return 0
        }
      }
    },
    data() {
      return {
        leisureConst,
        sportsConst,
        latelyBet: []
      }
    },
    methods:{
      initLatelyBet(){
        this.$store.commit(RECEIVE_SHOW_LOADING)
        getlatelyBet(this.kind).then(res => {
          if (res.data.success) {
            this.latelyBet = res.data.data
          }
          setTimeout(()=>{
            this.$store.commit(RECEIVE_HIDE_LOADING)
          },500)
        })
      }
    },
    created() {
      if(this.$store.state.isLogin){
        this.initLatelyBet()
        this.$bus.$on('leisureBetSuccess',()=>{
          this.initLatelyBet()
        })
      }
    },
  }
</script>

<style scoped>

</style>